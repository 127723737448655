body {
  display: initial;
  text-align: initial;
}

label,
input,
input::placeholder,
select,
select[value],
option {
  font-size: 0.875rem;
}

.forgot-password-link:hover {
  text-decoration: underline;
  cursor: pointer;
}
