.success {
  min-height: 100vh;
  text-align: center;
  padding: 40px 0;
  background: #ebf0f5;

  display: flex;
  justify-content: center;
  align-items: center;
}

.success h1 {
  color: #88b04b;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}

p {
  color: #404f5e;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  margin: 0;
}

.customSuccessCard {
  /* padding: 60px; */
  border-radius: 7px;
  background: #ebf0f5;
  box-shadow: 5px 5px 10px #c8ccd0, -5px -5px 10px #ffffff;
  display: inline-block;
  margin: 0 auto;
}

.checkmark {
  color: #9abc66;
  font-size: 50px !important;
  /* margin-left: -15px; */
  height: 200px !important;
  width: 200px !important;
  padding: 30px;
  border-radius: 50%;
  /* border: 2px solid #88b04b; */
}
