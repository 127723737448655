.cancel {
  text-align: center;
  padding: 40px 0;
  min-width: 100vw;
  min-height: 100vh;
  background: #f5edeb;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cancelDiv {
  width: 400px !important;
  border-radius: 7px;
  background: #f5edeb;
  box-shadow: 5px 5px 10px #d0c9c8, -5px -5px 10px #ffffff;
  /* margin: 0 24px !important; */
}

.cancelDiv h1 {
  color: #ee1212;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}

p {
  color: #404f5e;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  margin: 0;
}

.error {
  color: #e71313;
  /* font-size: 100px;
  line-height: 200px; */
  /* margin-left: -15px; */
  font-size: 50px !important;
  /* margin-left: -15px; */
  height: 200px !important;
  width: 200px !important;
  padding: 30px;
  border-radius: 50%;
}

.customCancelCard {
  /* padding: 60px; */
  width: 100%;
  height: 100%;
}
