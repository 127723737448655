* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Space Grotesk";
  font-size: 12px;
  height: 100vh;
  text-align: center;
  padding: 0 !important;
}
/*

img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}

h3 {
  text-align: center;
}

.para {
    text-align: center;
    font-size: 20px;
}
p{
    text-align: center;
}

button {
    margin:auto;
}

div {
  background: white;
} */

.imgCon {
  margin: 15px 0;

  display: flex;
  justify-content: center;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
  background: #f5edeb;
  box-shadow: inset 6px 6px 10px #d0c9c8, inset -6px -6px 10px #ffffff;
}

.customTipInput {
  /* border: 1px solid #dc3545; */
  border: none;
  width: 100% !important;
  font-size: 32px !important;
  font-weight: bold !important;
  background-color: transparent;
  /* border-radius: 5px; */
  padding: 10px 32px;
  padding-left: 50px !important;
  text-align: center;
}

.customTipInput:focus {
  border: none;
  outline: none;
  /* outline: 1px solid #dc3545; */
}

/* radio button */
.button {
  width: 100px;
  height: 40px;
  position: relative;
  border: 1px solid #dc3545;
  border-radius: 5px;
}

.button label,
.button input {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.button input[type="radio"] {
  opacity: 0.011;
  z-index: 100;
}

.button input[type="radio"]:checked + label {
  background: #dc3545;
  border-radius: 4px;
  color: white;
}

.button label {
  cursor: pointer;
  z-index: 90;
  line-height: 1.8em;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
